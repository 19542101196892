import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { BrowserRouter as Router } from "react-router-dom"

import { Base } from './Base'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#ff5555',
			contrastText: '#fff'
		},
		secondary: {
			main: '#013b52',
		},
	},
})

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Router>
				<Base />
			</Router>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
)


