/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { useHistory, Link } from "react-router-dom"
import HtmlParser from 'react-html-parser'

import Api from "./api"
import Session from "./api/Session"

import Register from "./Register"

const Esemenyek = (props: {upcoming?: boolean, archive?: boolean, thankYouNote?: boolean}) => {

	const [loaded, setLoaded] = useState(false)
	const [sessions, setSessions] = useState<Array<Session>>([])
	const [registerOpen, setRegisterOpen] = useState(false)
	const [selectedSession, setSelectedSession] = useState<Session | null>(null)
	const history = useHistory()

	const loadSessions = async () => {
		if (props.upcoming) setSessions(await Api.Session.getUpcoming())
		if (props.archive) setSessions(await Api.Session.getArchive())
	}

	useEffect(() => {
		setSessions([] as Session[])
		setLoaded(false)
		loadSessions().then(() => setLoaded(true))
	}, [props.archive, props.upcoming])

	useEffect(() => {
		Api.subscribeEvent("live:start", loadSessions)
		Api.subscribeEvent("live:end", loadSessions)
		return () => {
			Api.unsubscribeEvent("live:start", loadSessions)
			Api.unsubscribeEvent("live:end", loadSessions)
		}
	}, [])

	return (
		<Container style={{paddingTop: '30px'}}>
			{
				props.thankYouNote ? 
					<div className="block blue centered red-border">
						<div className="header">
							<div className="title large">Köszönjük a részvételt!</div>
							<br />
							<span className="subtitle">
								Szeretettel várunk vissza a következő eseményünkre is!
								<br />
								Az esemény felvételeit hamarosan elérhetővé tesszük.
							</span>
							<div className="description"></div>
							{
								loaded && !sessions.length ? 
								<>	
									<br />
									<Link to="/archivum">
										<Button size="large" color="primary" variant="contained">Korábbi felvételeink megtekintése</Button> 
									</Link>
								</>
								: null	
							}
						</div>
					</div>
				: null
			}
			{
				loaded && !props.thankYouNote && !sessions.length ? 
					<div className="block blue centered">
						<div className="header">
							<div className="title">Nincsenek közelgő eseményeink</div>
							<br />
							<span className="subtitle">Addig tekintsd meg a korábbi eseményeink felvételeit:</span>
							<div className="description"></div>
							<br />
							<Link to="/archivum">
								<Button size="large" color="primary" variant="contained">Az archívum megtekintése</Button> 
							</Link>
						</div>
					</div>
				: null
			}
			{
				sessions.map(session => {
					return (
						<div key={session.id} className={`block blue ${session.live ? 'live' : ''}`}>
							<div className="header" onClick={()=>{
								if (session.live) history.push("/", {directClick: session.live})
								if (props.archive) history.push("/archivum/" + session.id, {directClick: session.live})
							}}>
								<div className={`title ${session.live || props.archive ? 'clickable' : ''}`}>{session.name}</div>
								{
									session.live ? <div className="live">ÉLŐ</div> : null
								}
								<span className="subtitle">{session.date}</span>
							</div>
							<div className="description">
								{HtmlParser(session.description || "")}
							</div>
							{  props.upcoming && session.registrationNeeded && (session.plainRegistration || session.klubRegistration) ?
								<Button onClick={() => {
									setRegisterOpen(true)
									setSelectedSession(session)
								}} color="primary" variant="contained">Regisztráció</Button>
								: null
							}							
							{ props.archive ?
								<Link to={"/archivum/" + session.id}>
									<Button color="primary" variant="contained">Az esemény felvételeinek megtekintése</Button> 
								</Link>
								: null
							}
						</div>
					)
				})
			}
			<Register open={registerOpen} session={selectedSession} onClose={() => setRegisterOpen(false)} />
		</Container>
	)
}

export default Esemenyek