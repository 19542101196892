import { useState, useEffect } from "react"
import YouTube from 'react-youtube'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Session from './api/Session'
import DownloadIcon from '@material-ui/icons/Attachment'
import { IconButton, Tooltip } from "@material-ui/core"

const SessionPlayer = (props: {session: Session | null}) => {

	const [videoId, setVideoId] = useState<string | null>(null)
	const [player, setPlayer] = useState<any>(null)

	const embedDomain = window.localStorage.dev === "true" ? "localhost" : window.origin.replace("https://", "")

	useEffect(() => {
		setVideoId(props?.session?.history ? props?.session.HistoryVideos[0]?.videoId as string : props?.session?.videoId as string)
		if (!props.session?.error) window.sessionStorage.setItem("watching", "1")
		else window.sessionStorage.removeItem("watching")
	}, [props.session])

	useEffect(() => {
		return () => {
			window.sessionStorage.removeItem("watching")
		}
	}, [])

	return (
		<div>
			{ props?.session?.error ? 
					<div className="block error">
						<div className="title">Korlátozott hozzáférés</div>
						Ez az adás csak egyéni link birtokában tekinthető meg.
					</div>
				: null
			}
			{ !props?.session?.error ?
				<Container className="embed-container">
					<Grid container className="embed">
						{ !props?.session?.chat && !props?.session?.history ? <Grid item md={1} /> : null }
						<Grid item xs={11}>
							<div className="title">{props.session?.name}</div>
						</Grid>
						{ !props?.session?.chat && !props?.session?.history ? <Grid item md={1} /> : null }
						<Grid item md={props?.session?.chat || props?.session?.history ? 9 : 10} xs={12}>
							{/* <div className="name">{props.session.name}</div> */}
							<YouTube
								videoId={props?.session?.history ? undefined : videoId as string}
								containerClassName="video"
								className="video-inner" 
								onStateChange={(event)=>{
									if (event.target.getVideoData().video_id !== videoId) setVideoId(event.target.getVideoData().video_id)
								}}
								onReady={(event)=>{
									setPlayer(event.target)
									if (props?.session?.history) event.target.cuePlaylist(props?.session?.HistoryVideos.map((v: any) => v.videoId))
								}}
								opts={{
									playerVars: {
										autoplay: props?.session?.live ? 1 : 0,
										hl: 'hu',
										modestbranding: 1,
										rel: 0,
										controls: props?.session?.control ? 1 : 0,
										showinfo: 0,
										loop: props?.session?.loop ? 1 : 0,
										origin: window.location.origin,
										//playlist: props?.session?.loop ? props?.session?.videoId : ''
									}
								}}
							/>
						</Grid>
						
					{ props?.session?.chat ?
						<Grid item md={3} xs={12}>
							<iframe title="chat" className="chat" allowFullScreen frameBorder="0" src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${embedDomain}`}></iframe>
						</Grid>
					: null }
					{props?.session?.history ? 
						<Grid item md={3} xs={12}>
							<div className="history">
							{
								props?.session?.HistoryVideos.map((video: any, index: number) => {
									return (
										<div className={`history-item ${videoId === video.videoId ? 'active' : ''}`} onClick={()=>{
											if (player !== null) player.playVideoAt(index)
										}}>
											<div className="title">
												{video.title}
											</div>
											{video.presenter}
											{ video.File && <Tooltip title="Csatolmány letöltése" arrow>
												<IconButton target="_blank" href={"/letoltes/" + video.File.filename} className="download-button" onClick={e => e.stopPropagation()} >
													<DownloadIcon/>
												</IconButton>
											</Tooltip>
											}
										</div>
									)
								})
							}
							</div>
						</Grid>
					: null}
					</Grid>
				</Container>
			: null }
		</div>
	);
}

export default SessionPlayer