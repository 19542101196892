import axios, { AxiosInstance } from "axios"
import AdminResource from "./AdminResource"
import SessionResource from "./SessionResource"
import io from "./io"
import { Socket } from "socket.io-client"

type SubscribableEvent = "connect" | "startup" | "live:start" | "live:end" | "live:change"
type EmitableEvent = "code:set" | "attendance"

class InfoTanariumApiClient {

	public Session: SessionResource
	public Admin: AdminResource
	public io: Socket

	private axiosClient: AxiosInstance

	constructor(public apiUrl: string) {
		this.axiosClient = axios.create({
			baseURL: this.apiUrl,
		})

		this.axiosClient.interceptors.request.use(config => {
			if (Boolean(sessionStorage.token) && config.url?.includes("admin")) config.headers.Authorization = "Bearer " + sessionStorage.token
			return config
		})

		this.Session = new SessionResource(this.axiosClient)
		this.Admin = new AdminResource(this.axiosClient)
		
		this.io = io
	}

	public subscribeEvent(event: SubscribableEvent, callback: (data:any) => void) {
		this.io.on(event, callback)
	}

	public unsubscribeEvent(event: SubscribableEvent, callback: (data:any) => void) {
		this.io.off(event, callback)
	}

	public emitEvent(event: EmitableEvent, data: any) {
		this.io.emit(event, data)
	}
}

export default InfoTanariumApiClient