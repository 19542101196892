import { useState, useEffect } from "react"
import FileDownload from "js-file-download"

import "./Admin.scss"

import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from "@material-ui/core/Button"
import Switch from '@material-ui/core/Switch'
import Select from "@material-ui/core/Select"
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import DownloadIcon from '@material-ui/icons/GetApp'
import UploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Snackbar from '@material-ui/core/Snackbar'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextEditor from './TextEditor'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Skeleton from '@material-ui/lab/Skeleton'

import Api from "./api"
import Session from "./api/Session"

import Status from "./Status"
import HistoryVideo from "./api/HistoryVideo"
import { Typography } from "@material-ui/core"

const dateToDateInputString = (d: Date) => {
	let ds = d.getFullYear().toString() + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0')
	let ts = d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0')
	return ds + "T" + ts
}

const Admin = () => {

	const [password, setPassword] = useState("")
	const [loggedIn, setLoggedIn] = useState(Boolean(window.sessionStorage.token))

	const [sessions, setSessions] = useState<Session[]>([])
	const [sessionId, setSessionId] = useState<number | null>(null)
	const [session, setSession] = useState<Session | null>(null)
	const [sessionStats, setSessionStats] = useState<{registrationsCount: number, itmpRegistrationsCount: number} | null>(null)	

	const [uploadFileHistoryVideoId, setUploadFileHistoryVideoId] = useState<null|number>(null)
	const [fileUploadSuccessOpen, setFileUploadSuccessOpen] = useState(false)

	const [modified, setModified] = useState(false)

	const [savedOpen, setSavedOpen] = useState<boolean>(false)

	const getSession = async () => {
		if (!sessionId) return
		setSession(await Api.Admin.getSession(sessionId))
		setSessionStats(await Api.Admin.getSessionStats(sessionId))
		setModified(false)
	}

	const getSessions = async () => {
		setSessions(await Api.Admin.getSessions())		
	}

	const saveSession = async () => {
		if (session?.id) {
			session.HistoryVideos = session.HistoryVideos.map((historyVideo, order) => { return {...historyVideo, order}})
			await Api.Admin.saveSession(session.id, session)
			await getSessions()
			setSavedOpen(true)
			setModified(false)
		}
	}

	const createSession = async () => {
		let session = await Api.Admin.createSession()
		await getSessions()
		setSessionId(session.id)
		setModified(false)
	}

	const setProp = (key: keyof Session, value: any) => {
		let _session: {[index: string]:any} = {...session}
		_session[key] = value
		setSession(_session as Session)
		setModified(true)
	}

	const findHistoryVideoIndex = (historyVideoId: number) => {
		return session?.HistoryVideos.findIndex(historyVideo => historyVideo.id === historyVideoId)
	}

	const setHistoryVideoProp = (index: number, key: keyof HistoryVideo, value: any) => {
		let _session: {[index: string]:any} = {...session}
		_session.HistoryVideos[index][key] = value
		setSession(_session as Session)
		setModified(true)
	}

	useEffect(() => {
		getSession()
	}, [sessionId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (loggedIn) getSessions()
	}, [loggedIn])

	useEffect(() => {
		if (session?.history) setProp("chat", false)
	}, [session?.history])  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (session?.live) setProp("history", false)
	}, [session?.live]) // eslint-disable-line react-hooks/exhaustive-deps

	const login = () => {
		Api.Admin.login(password).then((data) => {
			window.sessionStorage.setItem("token", data.token)
			setLoggedIn(true)
			getSessions()
		}).catch(() => {
			alert("Rossz jelszó!")
			setPassword('')
		})
	}

	if (!loggedIn) return (
	<Dialog open maxWidth="xs" fullWidth>
		<DialogTitle>Bejelentkezés</DialogTitle>
		<DialogContent style={{paddingBottom: '25px'}}>
			<Grid container spacing={2}>
				<Grid item xs={10}>
					<TextField
						type="password"
						fullWidth
						variant="outlined"
						label="Jelszó"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={(e) => {if (e.key === "Enter") login()}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" fullWidth color="primary" style={{height: '100%'}} onClick={login} >
						<ExitToAppIcon />
					</Button>
				</Grid>
			</Grid>
		</DialogContent>
	</Dialog>)

	return (
		<Grid container className="admin">

			<input style={{display: 'none'}} id="file-upload" type="file" 
				onChange={()=>{
					const uploadElement = document.getElementById("file-upload") as HTMLInputElement
					const files = uploadElement?.files
					if (files && sessionId && uploadFileHistoryVideoId) {
						const data = new FormData()
						for(let i = 0; i < files.length; i++) {
							data.append('file', files[i])
						}
						Api.Admin.uploadFile(sessionId, uploadFileHistoryVideoId, data).then((result)=>{
							const index = findHistoryVideoIndex(uploadFileHistoryVideoId)
							
							if (typeof index !== 'undefined' && index !== -1) {
								setHistoryVideoProp(index, "FileId", result.data.id)
								setHistoryVideoProp(index, "File", result.data)
							}
							setFileUploadSuccessOpen(true)
						}).finally(()=>{
							uploadElement.value=""
							setUploadFileHistoryVideoId(null)
						})
					}
					
				}}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={fileUploadSuccessOpen}
				onClose={() => setFileUploadSuccessOpen(false)}
				message="Fájl feltöltve"
				autoHideDuration={2000}
			/>

			<Grid xs={12}>
				<img src="/logo.png" className="logo" alt="infoTanárium" />
			</Grid>
			<Grid xs={3} className="col" style={{padding: '20px 10px 20px 20px', position:'relative'}}>
				<Paper className="paper" style={{position: 'sticky', width: '100%', top: '20px'}}>
					<div className="title">
						Események
						<Tooltip title="Új léterhozása" placement="left" arrow>
							<Button onClick={()=>createSession()} className="circle-button" variant="contained" color="primary"><AddIcon /></Button>
						</Tooltip>
					</div>
					<Divider />
					<List className="list">
						{
							sessions.map((session: Session) => {
								let selected = session.id === sessionId
								let title = (session.calendarDate) ? `[${new Date(session.calendarDate).toLocaleDateString()}] ${session.name}` : session.name
								return (
									<ListItem onClick={()=>{
										//setSessionId(null)
										if (session.id !== sessionId) {
											setSession(null)
											setSessionStats(null)
											setSessionId(session.id)
										}
										
										/* window.setTimeout(() => {
											setSessionId(session.id)
										}, 50) */
										
									}} button selected={selected}>
										<ListItemText>
											{session.live ? <div className="live"></div> : null}
											{selected ? <b>{title}</b> : title}
										</ListItemText>
									</ListItem>
								)
							})
						}
						
					</List>
					<Divider />
				</Paper>
			</Grid>
			<Grid xs={9} className="col" style={{padding: '20px 20px 20px 10px'}}>
				{ session?.id ?
					<Paper className="paper pad">
						<Grid container spacing={2}>
							<Grid item xs={9}>
								<TextField
									label="Cím"
									fullWidth
									variant="outlined"
									value={session?.name || ''}
									onChange={e=>setProp("name", e.target.value)}
									InputLabelProps={{ shrink: Boolean(session?.name) }}
									inputProps={{style: {fontWeight: 600, fontSize: '20px'}}}
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel>Állapot</InputLabel>
									<Select
										value={
											session?.live ? Status.Live :
												session?.upcoming ? Status.Upcoming :
													session?.archived ? Status.Archived : Status.Hidden
										}
										onChange={(e: any) => {
											let value: Status = e.target.value
											let _session: any = {...session}
											
											_session.archived = value === Status.Archived
											_session.upcoming = value === Status.Upcoming || value === Status.Live
											_session.live = value === Status.Live
											
											setSession(_session as Session)
											setModified(true)
										}}
										label="Állapot"
										style={{lineHeight: '25px'}}
									>
										<MenuItem value={Status.Live}>
											<div className="live"></div><b>Élő</b>
										</MenuItem>
										<MenuItem value={Status.Hidden}>
											Rejtett
										</MenuItem>
										<MenuItem value={Status.Upcoming}>
											Jövőbeni
										</MenuItem>
										<MenuItem value={Status.Archived}>
											Archív
										</MenuItem>
										
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5}>
								<TextField
									label="Dátum"
									fullWidth
									size="small"
									variant="outlined"
									value={session?.date || ''}
									onChange={e=>setProp("date", e.target.value)}
									InputLabelProps={{ shrink: Boolean(session?.date) }}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									label="Kezdés"
									fullWidth
									size="small"
									variant="outlined"
									type="datetime-local"
									value={session?.calendarDate ? dateToDateInputString(new Date(session?.calendarDate)) : ''}
									onChange={e=>setProp("calendarDate", new Date(e.target.value).toISOString())}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									label="Befejezés"
									fullWidth
									size="small"
									variant="outlined"
									type="datetime-local"
									value={session?.calendarEndDate ? dateToDateInputString(new Date(session?.calendarEndDate)) : ''}
									onChange={e=>setProp("calendarEndDate", new Date(e.target.value).toISOString())}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={7}></Grid>

							<Grid item xs={7}>
								<FormControlLabel
									style={{paddingRight: '15px', borderRight: '1px solid #E0E0E0'}}
									label="Chat"
									disabled={session?.history}
									labelPlacement="end"
									control={
										<Checkbox checked={Boolean(session?.chat)} onChange={e=>setProp("chat", e.target.checked)} />
									}
								/>
								<FormControlLabel
									style={{paddingRight: '15px', borderRight: '1px solid #E0E0E0'}}
									label="Loop"
									labelPlacement="end"
									control={
										<Checkbox checked={Boolean(session?.loop)} onChange={e=>setProp("loop", e.target.checked)} />
									}
								/>
								<FormControlLabel 
									style={{paddingRight: '15px', borderRight: '1px solid #E0E0E0'}}
									label="Controls"
									labelPlacement="end"
									control={
										<Checkbox checked={Boolean(session?.control)} onChange={e=>setProp("control", e.target.checked)} />
									}
								/>
								<FormControlLabel 
									label="Archív leírás"
									labelPlacement="end"
									control={
										<Checkbox checked={Boolean(session?.seperateArchiveDescription)} onChange={e=>setProp("seperateArchiveDescription", e.target.checked)} />
									}
								/>
							</Grid>
							
							<Grid item xs={session?.seperateArchiveDescription ? 6 : 12}>
								<Typography>Leírás</Typography>
								<TextEditor
									value={session?.description || ''}
									onChange={(value: string)=>setProp("description", value)}
								/>
							</Grid>
							{ session?.seperateArchiveDescription ?
								<Grid item xs={6}>
									<Typography>Archív leírás</Typography>
									<TextEditor
										value={session?.archiveDescription || ''}
										onChange={(value: string)=>setProp("archiveDescription", value)}
									/>
								</Grid>
							: null}

							<Grid item xs={12}>
								<FormControlLabel 
									label="Több videó"
									labelPlacement="end"
									disabled={Boolean(session?.live)}
									control={
										<Checkbox checked={Boolean(session?.history)} onChange={e => setProp("history", e.target.checked)} />
									}
								/>
							</Grid>
							{ !session?.history ?
							<Grid item xs={4}>
								<TextField
									label="YouTube Video ID"
									fullWidth
									size="small"
									variant="outlined"
									value={session?.videoId || ''}
									onChange={e=> {
										let url = e.target.value
										url = url.replace("https://youtu.be/", "")
										let urls = url.split("watch?v=")
										if (urls.length > 1) url = urls[1].split("&")[0]
										setProp("videoId", url)
									}}
									InputLabelProps={{ shrink: Boolean(session?.videoId) }}
								/>
							</Grid> :
							<Grid item xs={12}> 
								<Paper style={{padding: '10px'}} elevation={3} >
									<Grid container spacing={1}>
										<Grid item xs={2}>
											Video ID
										</Grid>
										<Grid item xs={6}>
											Cím
										</Grid>
										<Grid item xs={4}>
											Előadó
										</Grid>
										{ session?.HistoryVideos.map((historyVideo, index) => {
											return <>
												<Grid item xs={2}>
													<TextField
														variant="outlined"
														size="small"
														fullWidth
														value={session?.HistoryVideos[index].videoId}
														onChange={e => setHistoryVideoProp(index, "videoId", e.target.value)}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														variant="outlined"
														size="small"
														fullWidth
														value={session?.HistoryVideos[index].title}
														onChange={e => setHistoryVideoProp(index, "title", e.target.value)}
													/>
												</Grid>
												<Grid item xs={4} style={{display: 'flex'}}>
													<TextField
														variant="outlined"
														size="small"
														value={session?.HistoryVideos[index].presenter}
														onChange={e => setHistoryVideoProp(index, "presenter", e.target.value)}
														style={{flex: 1}}
													/>
													<Tooltip arrow title={"Fájl feltöltése"}>
														<label htmlFor="file-upload" onClick={() => setUploadFileHistoryVideoId(historyVideo.id as number)}>
															<Button component="span" color="primary" variant="contained" style={{height: '40px', padding: '6px 6px', minWidth: '40px', marginLeft: '8px'}}>
																<UploadIcon style={{height: '20px'}} />
															</Button>
														</label>
													</Tooltip>
													<Tooltip arrow title={historyVideo?.File?.filename || ""}>
														<Button target="_blank" href={"/letoltes/" + historyVideo?.File?.filename} disabled={!historyVideo?.FileId} color="secondary" variant="contained" style={{height: '40px', padding: '6px 6px', minWidth: '40px', marginLeft: '8px'}}>
															<DownloadIcon style={{height: '20px'}} />
														</Button>
													</Tooltip>
													<Tooltip arrow title={"Fájl eltávolítása"}>
														<Button onClick={() => {
															Api.Admin.removeFile(historyVideo?.FileId as number).then(() => {
																setHistoryVideoProp(index, "FileId", null)
																setHistoryVideoProp(index, "File", null)
															})
														}} disabled={!historyVideo?.FileId} color="secondary" variant="contained" style={{height: '40px', padding: '6px 6px', minWidth: '40px', marginLeft: '8px'}}>
															<DeleteIcon style={{height: '20px'}} />
														</Button>
													</Tooltip>
												</Grid>
											</>
											})
										}
										<Grid item xs={1}>
										<Button variant="contained" color="secondary" onClick={()=>{
												let _session: Session = {...session}
												_session.HistoryVideos.push({id: undefined, videoId: "", title: "", presenter: "", order: -1})
												setSession(_session)
											}}>
												<AddIcon />
											</Button>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							}
							<Grid item xs={12}><Divider/></Grid>
							<Grid item xs={12}>
								<FormControlLabel 
									label="Regisztráció szükséges"
									labelPlacement="start"
									control={
										<Switch checked={Boolean(session?.registrationNeeded)} onChange={e => setProp("registrationNeeded", e.target.checked)} />
									}
								/>
							</Grid>
							<Collapse in={Boolean(session?.registrationNeeded)} style={{padding: '0 30px 5px 25px', width: '100%'}}>
								<Grid item xs={12} style={{marginBottom: '20px'}}>
									<FormControlLabel 
										label="Helyi regisztráció"
										style={{paddingRight: '15px', borderRight: '1px solid #E0E0E0'}}
										labelPlacement="end"
										control={
											<Checkbox checked={Boolean(session?.plainRegistration)} onChange={e => setProp("plainRegistration", e.target.checked)} />
										}
									/>
									<FormControlLabel 
										label="ITMP Klub regisztráció"
										style={{paddingRight: '15px', borderRight: '1px solid #E0E0E0'}}
										labelPlacement="end"
										control={
											<Checkbox checked={Boolean(session?.klubRegistration)} onChange={e => setProp("klubRegistration", e.target.checked)} />
										}
									/>
									<span>
										Regisztrációk száma (összes / ITMP Klub):
										&nbsp;
										{ sessionStats === null ?
											<Skeleton style={{display: 'inline-block', transform: 'none'}} width={70} height={23}/>
											:
											<><b>{sessionStats?.registrationsCount}</b> / {sessionStats?.itmpRegistrationsCount}</>
										}
										<Button variant="contained" color="secondary" onClick={()=>{
											Api.Admin.exportSession(session?.id).then(response => {
												console.log(response)
												FileDownload(response.data, `${session?.name}.xlsx`)
											})
										}} style={{marginLeft: '20px'}}>
											<DownloadIcon style={{margin: '0 7px 0 -7px', height: '22px'}} />
											Export letöltése
										</Button>
									</span>
									
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Email - Tárgy"
										variant="outlined"
										size="small"
										fullWidth
										value={session?.confirmEmailSubject || ''}
										onChange={e => setProp("confirmEmailSubject", e.target.value)}
										InputLabelProps={{ shrink: Boolean(session?.confirmEmailSubject) }}
									/>
								</Grid>
								<Grid item xs={12} style={{marginTop: '5px'}}>
									<TextEditor
										value={session?.confirmEmailBody || ''}
										onChange={(value: string)=>setProp("confirmEmailBody", value)}
									/>
								</Grid>
							</Collapse>
							<Grid item xs={12}><Divider/></Grid>
							<Grid item xs>
								<Button
									variant="contained"
									color="primary"
									size="large"
									disabled={!modified || (session?.live && !session?.videoId)}
									onClick={saveSession}
								>
									<SaveIcon className="icon left" /> Mentés
								</Button>
							</Grid>
						</Grid>
					</Paper> : null 
				}
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={savedOpen}
				onClose={() => setSavedOpen(false)}
				message="Sikeres mentés!"
			/>
		</Grid>
	)
				/*
	return (
		<div className="admin">
			<FormControl variant="outlined">
				<InputLabel></InputLabel>
				<Select style={{width: '500px'}} value={sessionId} onChange={(e: any)=>setSessionId(e.target.value)}>
					{
						sessions.map((session: Session) => {
							return <MenuItem value={session.id} >{session.name}</MenuItem>
						})
					}
				</Select>
			</FormControl>
			<Button onClick={createSession} color="secondary" variant="contained" style={{height: '56px', marginLeft: '10px'}}>+</Button>
			<br />
			{
			sessionId ?	<>
			<br />
			<fieldset>
			<TextField style={{margin: '10px 0', width: '700px'}} label="Név" size="small" variant="outlined" type="text" value={name} onChange={e=>setName(e.target.value)} />
			<br />
			<TextField style={{margin: '10px 0', width: '400px'}} label="Dátum" size="small" variant="outlined" type="text" value={date} onChange={e=>setDate(e.target.value)} />
			<br />
			Leírás
			<TextEditor style={{margin: '10px 0'}} value={description} onChange={(value: string)=>setDescription(value)} />
			<TextField style={{marginTop: '10px', width: '500px'}} label="Regisztrációs link" size="small" variant="outlined" value={regLink} onChange={e=>setRegLink(e.target.value)} />
			<br/>
			<br/>
			<FormControlLabel 
				label="Élő adás"
				labelPlacement="start"
				control={
					<Switch checked={live} onChange={e=>{
						setLive(e.target.checked)
						//if (!e.target.checked) setChat(false)
					}} />
				}
			/>
			<br/>
			<FormControlLabel 
				label="Jövőbeni"
				labelPlacement="start"
				control={
					<Switch checked={upcoming} onChange={e=>setUpcoming(e.target.checked)} />
				}
			/>
			<br/>
			<FormControlLabel 
				label="Több videó"
				labelPlacement="start"
				control={
					<Switch checked={history} onChange={e=>{
						setHistory(e.target.checked)
						if (e.target.checked) setChat(false)
					}} />
				}
			/>
			{ !history ? 
			<>
				<br/>
				<TextField style={{marginTop: '10px'}} label="Video ID" size="small"  variant="outlined" type="text" value={videoId} onChange={e=>setVideoId(e.target.value)} disabled={history} />
				<br/>
			</>
			 : null }
			</fieldset>
			<br />
			{ history ?
				<>
				<fieldset>
					<legend>Videók</legend>		
					<br />
					{historyVideos.map((video, index)=>{
						return (
							<div style={{marginBottom: '10px'}}>
								<TextField style={{width: '150px'}} label="ID" size="small"  variant="outlined" value={historyVideos[index].videoId} onChange={(e)=>{let hv = [...historyVideos]; hv[index].videoId=e.target.value; setHistoryVideos(hv)}} />
								<TextField style={{margin: '0 10px', width: '600px'}} label="Cím" size="small"  variant="outlined" value={historyVideos[index].title} onChange={(e)=>{let hv = [...historyVideos]; hv[index].title=e.target.value; setHistoryVideos(hv)}} />
								<TextField label="Előadó" size="small"  variant="outlined" value={historyVideos[index].presenter} onChange={(e)=>{let hv = [...historyVideos]; hv[index].presenter=e.target.value; setHistoryVideos(hv)}} />
							</div>
						)
					})}
					<Button variant="contained" color="secondary" onClick={()=>{
						let hv: Array<any> = [...historyVideos]
						hv.push({videoId: "", title: "", presenter: ""})
						setHistoryVideos(hv)
					}}>+</Button>
				</fieldset>
				<br />
				</>
				: null
			}
			<fieldset>
			<FormControlLabel 
				label="Loop"
				labelPlacement="start"
				control={
					<Switch checked={loop} onChange={e=>setLoop(e.target.checked)} />
				}
			/>
			<br />
			<FormControlLabel 
				label="Controls"
				labelPlacement="start"
				control={
					<Switch checked={control} onChange={e=>setControl(e.target.checked)} />
				}
			/>
			<br />
			<FormControlLabel 
				label="Chat"
				disabled={history}
				labelPlacement="start"
				control={
					<Switch checked={chat} onChange={e=>setChat(e.target.checked)} />
				}
			/>
			<br />
			<FormControlLabel 
				label="Code"
				labelPlacement="start"
				control={
					<Switch checked={codeNeeded} onChange={e=>setCodeNeeded(e.target.checked)} />
				}
			/>
			{
				codeNeeded ? <>
				<br/>
				<TextField label="Code table name" size="small"  variant="outlined" type="text" value={codeTable} onChange={e=>setCodeTable(e.target.value)} />
				</> : null
			}
			</fieldset>
			<br />
			<TextField label="Jelszó" size="small"  variant="outlined" type="password" value={password} onChange={e=>setPassword(e.target.value)} />
			<br />
			<br />
			<Button onClick={putVideo} variant="contained" color="secondary">Mentés</Button></> : null}
		</div>
	)
	*/
}

export default Admin